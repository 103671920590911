import React, { useContext } from 'react';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { isArticleFeedsAllowed } from "utils/helpers";

import SubNavList from './subNavList';

import * as Style from './style';
import { FAL } from 'icons/fa';
import { Context as SettingsContext } from "components/setting/context";

const NavList = ({ data, toggleSubNav, toggleNavList }) => {
  const { settings } = useContext(SettingsContext);
  const isWorkflowEnabled = settings["workflow.enabled"].hasOwnProperty('stringValue') ? settings["workflow.enabled"]?.stringValue : "false";
  console.log("isWorkflowEnabled", isWorkflowEnabled); 
  const modifiedData = data?.links
    ?.map((link) => {
      // Remove workflow articles link from editorial nav menu when workflow.enables is false
      if (isWorkflowEnabled === 'false' && link.to === '/editorial' && link.children.length > 0) {
        let cloneLink = cloneDeep(link);
        let articleIndex = cloneLink.children.findIndex(s => (s.to.indexOf('/workflow/articles') !== -1));
        if (articleIndex >= 0) {
          cloneLink.children = [
            ...cloneLink.children.slice(0, articleIndex),
            ...cloneLink.children.slice(articleIndex + 1),
          ];
        }
        return cloneLink;
      }
      // if link is not belongs to article feeds page return link as it is
      if (link.to.indexOf("article-feeds") === -1) return link;

      // otherwise test that if article feeds page access is allowed to the client
      return isArticleFeedsAllowed() ? link : null;
    })
    ?.filter((value) => value);

  return (
    <Style.NavList>
      {modifiedData && modifiedData.map((item, index) => {
        const isExpanded = data.openParentID === index;
        const accordionClass = classNames({
          '-is-expanded': isExpanded,
        });
        const expandedIcon = isExpanded ? 'angle-down' : 'angle-right';

        return (
          <Style.LI key={index} className={accordionClass}>
            <Style.Link to={item.to} highlight={item.highlight} onClick={(e) => toggleNavList(e, index)}>
              <Style.LinkInner id={item.text?.toKebabCase()}><FAL icon={item.icon} />{item.text}</Style.LinkInner>
              { item.children.length ? (
                <Style.Arrow onClick={(e) => toggleSubNav(e, index)}>
                  <FAL icon={expandedIcon} />
                </Style.Arrow>
              ) : null }
            </Style.Link>

            { item.children.length ? <SubNavList openParentID={data.openParentID} parent={item} parentIndex={index} /> : null }
          </Style.LI>
        );
      })}
    </Style.NavList>
  );
};

export default NavList;
