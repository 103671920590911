import React, { useEffect, useContext } from 'react';
import { Switch, Route, Redirect, withRouter, useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import loadable from '@loadable/component';
import { initDB } from 'react-indexed-db';

import Navigation from './components/navigation/index';
import Header from './components/header/index';
import DrawerProvider, { Context as DrawerContext } from 'components/drawer/context';
import { environmentOptions } from 'utils/helpers';

import Auth, { AuthPermissions } from './auth';
import { Normalize, DefaultStyles } from './styles/index';
import { AntOverrides } from './styles/ant-overrides';
import { AppWrapper, Body } from './styles/app';
import LoginForm from 'views/login';

import ('antd/lib/menu/style/css');
import ('antd/lib/input-number/style/css');

import ('./icons/init-font-awesome');

import Drawer from 'components/drawer';
import OrderProvider, { Context as OrderContext } from "components/order/context";
import MediaExportProvider from "components/mediaExport/context";
import NotificationProvider from 'components/notifications/context';
import ImpersonateProvider from "components/impersonate/context";
import ReportProvider from "components/fileReport/context";
import ProgressBar from "components/order";
import ReportProgressBar from "components/fileReport";
import MediaProgressBar from "components/mediaExport";
import SettingsProvider from 'components/setting/context';
import Notice from 'components/notice';
import { DBConfig } from 'views/users/DBConfig';
import { TitleComponent } from 'components/title';
import PromptProvider, { Context as PromptContext } from 'hooks/usePrompt/context';
import usePrompt from 'hooks/usePrompt';

import { deleteCookie, getCookieByName } from 'utils/cookiesHelper';


if (window.indexedDB) {
  initDB(DBConfig, 1, 'user');
}

console.log('deploy works')

/**
 * Code Splitting
 * Asynchronously load components as they are fetched per Router. Webpack
 * creates chunks out of these loadable imports.
 */
const Login = loadable(() => import(/* webpackChunkName: "Login" */ './views/login/index'));
const StaticNetwork = loadable(() => import(/* webpackChunkName: "StaticApiError" */ './views/static/network'));
const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */ './views/dashboard/index'));
const Analytics = loadable(() => import(/* webpackChunkName: "Analytics" */ './views/analytics/index'));
const DirectoryListing = loadable(() => import(/* webpackChunkName: "DirectoryListing" */ './views/directory/index'));
const DirectoryNew = loadable(() => import(/* webpackChunkName: "DirectoryNew" */ './views/directory/components/index'));
const DirectoryEdit = loadable(() => import(/* webpackChunkName: "DirectoryEdit" */ './views/directory/components/index'));
const Galleries = loadable(() => import(/* webpackChunkName: "Galleries" */ './views/media/galleries/index'));
const GalleryAddEdit = loadable(() => import(/* webpackChunkName: "GalleryAddEdit" */ './views/media/galleries/components/index'));
const FormBuilder = loadable(() => import(/* webpackChunkName: "FormBuilder" */ './views/formBuilder/index'));
const Taxonomy = loadable(() => import(/* webpackChunkName: "Taxonomy" */ './views/taxonomy/index'));
const SitePlacement = loadable(() => import(/* webpackChunkName: "SitePlacement" */ './views/siteplacement/index'));
const ArticleEdit = loadable(() => import(/* webpackChunkName: "ArticleEdit" */ './views/article/edit/index'));
const ArticleEditTrackChanges = loadable(() => import(/* webpackChunkName: "ArticleEditTrackChanges" */ './views/article/trackChanges'));
const ArticleList = loadable(() => import(/* webpackChunkName: "ArticleList" */ './views/article/index'));
const ArticleFeeds = loadable(() => import(/* webpackChunkName: "ArticleFeeds" */ './views/articleFeeds/index'));
const ArticleCompare = loadable(() => import(/* webpackChunkName: "ArticleCompare" */ './views/articleCompare/index'));
const EventList = loadable(() => import(/* webpackChunkName: "EventList" */ './views/event/index'));
const EventAddEdit = loadable(() => import(/* webpackChunkName: "EventEdit" */ './views/event/components/index'));
const PageList = loadable(() => import(/* webpackChunkName: "Pages" */ './views/page/index'));
const PageEdit = loadable(() => import(/* webpackChunkName: "PageEdit" */ './views/page/components/index'));
const PageAdd = loadable(() => import(/* webpackChunkName: "PageAdd" */ './views/page/components/index'));
const Comments = loadable(() => import(/* webpackChunkName: "Comments" */ './views/comments/index'));
const CommentEdit = loadable(() => import(/* webpackChunkName: "CommentEdit" */ './views/comments/edit/index'));
const Users = loadable(() => import(/* webpackChunkName: "Users" */ './views/users/index'));
const UserEdit = loadable(() => import(/* webpackChunkName: "UsersEdit" */ './views/users/edit/index'));
const Authors = loadable(() => import(/* webpackChunkName: "Authors" */ './views/authors/index'));
const AuthorEdit = loadable(() => import(/* webpackChunkName: "AuthorEdit" */ './views/authors/edit/index'));
const NavigationLink = loadable(() => import(/* webpackChunkName: "Navigation" */ './views/navigation/index'));
const Polls = loadable(() => import(/*webpackChunkName:"Polls" */ './views/poll/index'));
const PollEdit = loadable(() => import(/* webpackChunkName: "PollEdit" */ './views/poll/form/index'));
const PollAdd = loadable(() => import(/* webpackChunkName: "PollAdd" */ './views/poll/form/index'));
const EditorialContent = loadable(() => import(/*webpackChunkName:"EditorialContent" */ './views/editorial/index'));
const Redirects = loadable(() => import(/* webpackChunkName: "Redirects" */ './views/redirects/index'));
const RedirectEdit = loadable(() => import(/* webpackChunkName: "RedirectEdit" */ './views/redirects/edit/index'));
const EditorialEdit = loadable(() => import(/* webpackChunkName: "EditorialEdit" */ './views/editorial/edit/index'));
const EditorialAdd = loadable(() => import(/* webpackChunkName: "EditorialAdd" */ './views/editorial/edit/index'));
const Blogs = loadable(() => import(/* webpackChunkName: "Blogs" */ './views/blogs/index'));
const Publications = loadable(() => import(/* webpackChunkName: "Publications" */ './views/publications/index'));
const EditionAdd = loadable(() => import(/* webpackChunkName: "EditionAdd" */ './views/publications/editions/index'));
const EditionEdit = loadable(() => import(/* webpackChunkName: "EditionEdit" */ './views/publications/editions/index'));
const Newsletters = loadable(() => import(/* webpackChunkName: "Newsletters" */ './views/newsletters/index'));
const IssueEdit = loadable(() => import(/* webpackChunkName: "IssueEdit" */ './views/newsletters/issues/index'));
const IssueAdd = loadable(() => import(/* webpackChunkName: "IssueAdd" */ './views/newsletters/issues/index'));
const IssuePreviewWindow = loadable(() => import(/* webpackChunkName: "IssuePreviewWindow" */ './views/newsletters/issues/issuePreviewWindow'));
const Metadata = loadable(() => import(/* webpackChunkName: "Metadata" */ './views/metadata/index'));
const Rss = loadable(() => import(/* webpackChunkName: "Rss" */ './views/rss/index'));
const SalesTax = loadable(() => import(/* webpackChunkName: "SalesTax" */ './views/salesTax/index'));
const Offers = loadable(() => import(/* webpackChunkName: "Offers" */ './views/offers/index'));
const OfferAdd = loadable(() => import(/* webpackChunkName: "OfferAdd" */ './views/offers/components/index'));
const OfferEdit = loadable(() => import(/* webpackChunkName: "OfferEdit" */ './views/offers/components/index'));
const ShippingRates = loadable(() => import(/* webpackChunkName: "shippingRate" */ './views/shippingRate/index'));
const ShippingZones = loadable(() => import(/* webpackChunkName: "ShippingZones" */ './views/shippingZones/index'));
const Classifieds = loadable(() => import(/* webpackChunkName: "Classifieds" */ './views/classified/index'));
const ClassifiedAddEdit = loadable(() => import(/* webpackChunkName: "ClassifiedAddEdit" */ './views/classified/components/index'));
const Settings = loadable(() => import(/*webpackChunkName:"Settings" */ './views/settings/index'));
const Products = loadable(() => import(/* webpackChunkName: "Products" */ './views/product/index'));
const ProductAdd = loadable(() => import(/* webpackChunkName: "ProductAdd" */ './views/product/components/index'));
const ProductEdit = loadable(() => import(/* webpackChunkName: "ProductEdit" */ './views/product/components/index'));
const rolesPrivileges = loadable(() => import(/* webpackChunkName: "rolesPrivileges" */ './views/rolesPrivileges/index'));
const Media = loadable(() => import(/* webpackChunkName: "media" */ './views/media/index'));
const Order = loadable(() => import(/* webpackChunkName: "order" */ './views/order/index'));
const OrderDetail = loadable(() => import(/* webpackChunkName: "orderDetail" */ './views/order/components/index'));
const OrderAdd = loadable(() => import(/* webpackChunkName: "orderAdd" */ './views/order/components/add/index'));
const Search = loadable(() => import(/* webpackChunkName: "Search" */ './views/search/index'));
const Workflow = loadable(() => import(/* webChunkName: "Workflow" */ './views/workflow/index'));
const FileReport = loadable(() => import(/* webChunkName: "FileReport" */ './views/reports/index'));
const WorkflowArticleReadOnly = loadable(() => import(/* webChunkName: "WorkflowArticleReadOnly" */ './views/articleWorkflow/readOnly/index'));
const WorkflowArticleListing = loadable(() => import(/* webChunkName: "WorkflowArticle" */ './views/articleWorkflow/index'));
/**
 * Set default global CSS styles
 * - Normalized stylesheets
 * - Default app styles
 */
const GlobalStyle = createGlobalStyle`
  ${Normalize}
  ${DefaultStyles}
  ${AntOverrides}
`;

const LayoutBody = withRouter(({ children, ...props }) => {
  const { show, toggleShow } = useContext(DrawerContext);
  const ENV_OPTIONS = environmentOptions();
  const apiStagingEnabled = localStorage.getItem('api.staging.enabled');
  const history = useHistory();
  const { show: showSaveChangesPrompt, setShow: setShowSaveChangesPrompt } = useContext(PromptContext);
  const { state } = useContext(OrderContext);
  const { callFromContext, page, exportClicked } = state;

  usePrompt(showSaveChangesPrompt);

  // Update show flag to false after each route change event
  useEffect(() => {
    history.listen(function (ev) {
      setShowSaveChangesPrompt(false);
    });
  }, []);

  /**
   * Notify user to login, when any api call get 403 response status
   */
  useEffect(() => {
    function handler() {
      if ((typeof show === 'string' && show !== 'login-modal') || (typeof show === 'object' && show.indexOf('login-modal') === -1)) {
        // Auth.clearToken();
        toggleShow('login-modal', true);
      }
    }

    function redirectToApiError() {
      return (
        <Redirect
          to={{
            pathname: '/api-error',
            from: props.location,
          }}
        />
      );
    }

    // Allow the API URL to point to stage; when triggered via a global variable.
    window.continuum = window.continuum || {
      api: {
        staging: {
          enable() {
            localStorage.setItem('api.staging.enabled', 'true');
            document.location.reload();
          },
          disable() {
            localStorage.setItem('api.staging.enabled', 'false');
            document.location.reload();
          },
          setHost(host) {
            const host1 = window.location.host.split(".")[0];
            if (host1 === "localhost:3000" || host1 === "continuum" || host1 === "continuum-stage") {
              localStorage.setItem('active.host', host);
              document.location.reload();
            }
          },
          setDefaultHost() {
            localStorage.removeItem('active.host');
            document.location.reload();
          }
        },
      },
    };

    document.addEventListener('token_expired', handler);
    document.addEventListener('api_failed', redirectToApiError);

    return () => {
      document.removeEventListener('token_expired', handler);
      document.removeEventListener('api_failed', redirectToApiError);
    };
  }, []);

  function onSuccessLogin() {
    toggleShow();
    document.location.reload();
  }

  /**
   * Determines if the local development or continuum site is in debug mode, by checking if the environment var
   * points to continuum-alpha. Used for showing a banner warning.
   */
  function isLocalDebugModeActive() {
    return ENV_OPTIONS.X_HOST_SHORTNAME !== 'continuum-alpha' && ['localhost:3000', 'continuum'].includes(ENV_OPTIONS.HOST);
  }

  return (
    <>
      <Body className="app-body" id= {(window.location.pathname.includes('editorial/newsletters')) ? "app-body-scroll" : "app-body"}>
        <div>
          {/* Keeps grid in-tact when Notice is enabled */}
          {isLocalDebugModeActive() && <Notice type="caution" text={`DEBUG MODE ENABLED: Using ${ENV_OPTIONS.X_HOST_SHORTNAME} data.`} show={true} style={{ margin: '0', borderRadius: '0', borderLeft: 'none', borderRight: 'none' }} />}
          {apiStagingEnabled === 'true' && <Notice type="caution" text={`Stage API is active.`} show={true} style={{ margin: '0', borderRadius: '0', borderLeft: 'none', borderRight: 'none' }} />}
          <Header />
        </div>
        {children}
      </Body>
      <Drawer id="login-modal" title="" hasTabs={true} showHeader={false} showAsModal={true}>
        <LoginForm onSuccessLogin={onSuccessLogin} isShowInModal={true} />
      </Drawer>
      <ProgressBar />
      <ReportProgressBar />
      <MediaProgressBar />
    </>
  );
});

/**
 * A Layout component that wraps all Route components. Loading each Route
 * in the body of the application, with a shared header, navigation, et cetera.
 */
const Layout = ({ children }) => {
  return (
    <SettingsProvider>
      <NotificationProvider>
        <MediaExportProvider>
          <OrderProvider>
            <ReportProvider>
              <DrawerProvider>
                  <ImpersonateProvider>
                    <GlobalStyle />
                    <AppWrapper id="appWrapper" className={(window.location.pathname.includes('editorial/newsletters')) ? "appWrapperScroll" : ""}>
                      <PromptProvider>
                        <LayoutBody>{children}</LayoutBody>
                      </PromptProvider>
                      <Navigation />
                    </AppWrapper>
                  </ImpersonateProvider>
              </DrawerProvider>
            </ReportProvider>
          </OrderProvider>
        </MediaExportProvider>
      </NotificationProvider>
    </SettingsProvider>
  );
};

/**
 * A custom Route component that checks to see if a component is a protected route,
 * if the user is not authenticated then they are redirected to login.
 */
const AuthRoute = ({ component: Component, privilege, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      Auth.isUserAuthenticated() ? (
        <AuthPermissions privilege={privilege} history={props.history} location={props.location}>
          <Layout>
            <Component {...props} />
          </Layout>
        </AuthPermissions>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            from: props.location,
          }}
        />
      )
    }
  />
);

/**
 * A custom Route component that checks to see if a component is a protected route,
 * if the user is not authenticated then they are redirected to login.
 * Specifically for routes which does not require sidebar menu and top header after login - ex. Newsletter Preview Page=/editorial/newsletters/78/issues/270/preview
 */
const AuthRouteWithoutSidebar = ({ component: Component, privilege, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      Auth.isUserAuthenticated() ? (
        <AuthPermissions privilege={privilege} history={props.history} location={props.location}>
          <SettingsProvider>
            <PromptProvider>
              <GlobalStyle />
              <Component {...props} />
            </PromptProvider>
          </SettingsProvider>
        </AuthPermissions>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            from: props.location,
          }}
        />
      )
    }
  />
);

function admin(name) {
  return `admin.${name}.manager`;
}

function sysadmin(name) {
  return `sysadmin.${name}.manager`;
}

const withTitle = (Component, title) => {
  return (props) => {
    return (
      <>
        <TitleComponent title={title} />
        <Component {...props} />
      </>
    );
  }
};

const App = () => {

  /**
   * Transfer cookie data to local storage as auth token
   */
  useEffect(() => {
    const authDataFromCookie = getCookieByName("epub-multisite-session");

    if (!authDataFromCookie) return;

    // Save passed auth token from sibling site(parent window) to local storage first
    Auth.storeCookieToLocalStorage(authDataFromCookie);
    // Always delete cookie if page opened by parent window
    deleteCookie("epub-multisite-session");
  }, []);

  // Advanced -> Custom Fields menu privileges
  const customFieldsPrivileges = [
    sysadmin('classified.detail'),
    sysadmin('directory.detail'),
    sysadmin('event.detail'),
    sysadmin('product.detail'),
    sysadmin('publication.edition.detail'),
    sysadmin('user.demographic')
  ];

  return (
    <Switch>
      <Route exact path="/login" component={withTitle(Login, "Login")} />
      <Route exact path="/api-error" component={withTitle(StaticNetwork, "Error")} />
      <AuthRoute exact path="/" component={withTitle(Dashboard, "Dashboard")} />
      <AuthRoute exact path="/dashboard" component={withTitle(Dashboard, "Dashboard")} />
      <AuthRoute exact path="/analytics" component={withTitle(Analytics, "Analytics")} />
      <AuthRoute exact path="/taxonomy" component={withTitle(Taxonomy, "Taxonomy")} privilege={sysadmin('taxonomy')} />
      <AuthRoute exact path="/siteplacement" component={withTitle(SitePlacement, "Site Placement")} privilege={admin('site.placement')} />
      <AuthRoute exact path="/editorial" component={withTitle(ArticleList, "Articles")} privilege={admin('article')} />
      <AuthRoute exact path="/editorial/articles" component={withTitle(ArticleList, "Articles")} privilege={admin('article')} />
      <AuthRoute exact path="/editorial/articles/:oid" component={withTitle(ArticleEdit, "Article")} privilege={admin('article')} />
      <AuthRoute exact path="/editorial/articles-compare/:oid" component={withTitle(ArticleCompare, "Article")} privilege={admin('article')} />
      <AuthRoute exact path="/editorial/articles/:oid/track-changes" component={withTitle(ArticleEditTrackChanges, "Track changes")} privilege={admin('article')} />
      <AuthRoute exact path="/editorial/workflow/articles" component={withTitle(WorkflowArticleListing, "Workflow")} privilege={admin('article')} />
      <AuthRoute exact path="/editorial/pages" component={withTitle(PageList, "Pages")} privilege={admin('page')} />
      <AuthRoute exact path="/editorial/pages/:oid" component={withTitle(PageEdit, "Page")} privilege={admin('page')} />
      <AuthRoute exact path="/editorial/pages/new" component={withTitle(PageAdd, "Page")} privilege={admin('page')} />
      <AuthRoute exact path="/editorial/comments" component={withTitle(Comments, "Comments")} privilege={admin('talkback')} />
      <AuthRoute exact path="/editorial/comments/:type/:oid" component={withTitle(CommentEdit, "Comment")} privilege={admin('talkback')} />
      <AuthRoute exact path="/editorial/authors" component={withTitle(Authors, "Authors")} privilege={admin('author')} />
      <AuthRoute exact path="/editorial/authors/:oid" component={withTitle(AuthorEdit, "Author")} privilege={admin('author')} />
      <AuthRoute exact path="/editorial/redirects" component={withTitle(Redirects, "Redirects")} privilege={admin('redirect')} />
      <AuthRoute exact path="/editorial/redirects/:oid" component={withTitle(RedirectEdit, "Redirect")} privilege={admin('redirect')} />
      <AuthRoute exact path="/editorial/navigation" component={withTitle(NavigationLink, 'Navigation')} privilege={admin('navigation')} />
      <AuthRoute exact path="/editorial/editorial-content" component={withTitle(EditorialContent, 'Editorial Content')} privilege={admin('editorial.content')} />
      <AuthRoute exact path="/editorial/editorial-content/:oid" component={withTitle(EditorialEdit, 'Editorial Content')} privilege={admin('editorial.content')} />
      <AuthRoute exact path="/editorial/editorial-content/new" component={withTitle(EditorialAdd, 'Editorial Content')} privilege={admin('editorial.content')} />
      <AuthRoute exact path="/media" component={withTitle(Media, 'Media')} privilege={admin('media')} />
      <AuthRoute exact path="/media/manager" component={withTitle(Media, 'Media')} privilege={admin('media')} />
      <AuthRoute exact path="/editorial/galleries" component={withTitle(Galleries, 'Image Galleries')} privilege={admin('image.gallery')} />
      <AuthRoute exact path="/editorial/galleries/:oid" component={withTitle(GalleryAddEdit, 'Image Gallery')} privilege={admin('image.gallery')} />
      <AuthRoute exact path="/editorial/blogs" component={withTitle(Blogs, 'Blogs')} privilege={admin('blog')} />
      <AuthRoute exact path="/editorial/publications" component={withTitle(Publications, 'Publications')} privilege={admin('publication')} />
      <AuthRoute exact path="/editorial/publications/:oid" component={withTitle(Publications, 'Publications')} privilege={admin('publication')} />
      <AuthRoute exact path="/editorial/publications/:publicationId/editions/new" component={withTitle(EditionAdd, 'Publication Edition')} privilege={admin('publication')} />
      <AuthRoute exact path="/editorial/publications/:publicationId/editions/:editionId" component={withTitle(EditionEdit, '')} privilege={admin('publication')} />
      <AuthRoute exact path="/editorial/newsletters" component={withTitle(Newsletters, 'Newsletters')} privilege={admin('newsletter')} />
      <AuthRoute exact path="/editorial/newsletters/:oid" component={withTitle(Newsletters, 'Newsletters')} privilege={admin('newsletter')} />
      <AuthRoute exact path="/editorial/newsletters/:newsletterId/issues/:issueId" component={withTitle(IssueEdit, 'Newsletter Issue')} privilege={admin('newsletter')} />
      <AuthRoute exact path="/editorial/newsletters/:newsletterId/issues/new" component={withTitle(IssueAdd, 'Newsletter Issue')} privilege={admin('newsletter')} />
      <AuthRouteWithoutSidebar exact path="/editorial/newsletters/:newsletterId/issues/:issueId/preview" component={withTitle(IssuePreviewWindow, 'Newsletter Issue Preview')} privilege={admin('newsletter')} />
      <AuthRoute exact path="/editorial/rss" component={withTitle(Rss, 'Rss')} privilege={admin('rss')} />
      <AuthRoute exact path="/editorial/articles/view/:oid" component={withTitle(WorkflowArticleReadOnly, 'Article')} />
      <AuthRoute exact path="/import/article-feeds" component={withTitle(ArticleFeeds, 'Article Feeds')} />
      <AuthRoute exact path="/revenue" component={withTitle(Classifieds, 'Classifieds')} privilege={admin('classified')} />
      <AuthRoute exact path="/revenue/classifieds" component={withTitle(Classifieds, 'Classifieds')} privilege={admin('classified')} />
      <AuthRoute exact path="/revenue/classifieds/:oid" component={withTitle(ClassifiedAddEdit, 'Classified')} privilege={admin('classified')} />
      <AuthRoute exact path="/revenue/classifieds/new" component={withTitle(ClassifiedAddEdit, 'Classified')} privilege={admin('classified')} />
      <AuthRoute exact path="/revenue/directory" component={withTitle(DirectoryListing, 'Directories')} privilege={admin('directory')} />
      <AuthRoute exact path="/revenue/directory/:oid" component={withTitle(DirectoryEdit, 'Directory')} privilege={admin('directory')} />
      <AuthRoute exact path="/revenue/directory/new" component={withTitle(DirectoryNew, 'Directory')} privilege={admin('directory')} />
      <AuthRoute exact path="/revenue/events" component={withTitle(EventList, 'Events')} privilege={admin('event')} />
      <AuthRoute exact path="/revenue/events/:oid" component={withTitle(EventAddEdit, 'Event')} privilege={admin('event')} />
      <AuthRoute exact path="/revenue/offers" component={withTitle(Offers, 'Offers')} privilege={admin('offer')} />
      <AuthRoute exact path="/revenue/offers/:oid" component={withTitle(OfferEdit, 'Offer')} privilege={admin('offer')} />
      <AuthRoute exact path="/revenue/offers/new" component={withTitle(OfferAdd, 'Offer')} privilege={admin('offer')} />
      <AuthRoute exact path="/revenue/orders/new" component={withTitle(OrderAdd, 'Orders')} privilege={admin('order')} />
      <AuthRoute exact path="/revenue/orders/" component={withTitle(Order, 'Orders')} privilege={admin('order')} />
      <AuthRoute exact path="/revenue/orders/:oid" component={withTitle(OrderDetail, 'Orders')} privilege={admin('order')} />
      <AuthRoute exact path="/revenue/shipping-rates" component={withTitle(ShippingRates, 'Shipping Rates')} privilege={admin('shipping')} />
      <AuthRoute exact path="/revenue/shipping-zones" component={withTitle(ShippingZones, 'Shipping Zones')} privilege={admin('shipping')} />
      <AuthRoute exact path="/revenue/sales-tax" component={withTitle(SalesTax, "Sales Tax")} privilege={admin('product')} />
      <AuthRoute exact path="/marketing" component={withTitle(Polls, 'Polls')} privilege={admin('poll')} />
      <AuthRoute exact path="/marketing/polls" component={withTitle(Polls, 'Polls')} privilege={admin('poll')} />
      <AuthRoute exact path="/marketing/polls/:oid" component={withTitle(PollEdit, 'Poll')} privilege={admin('poll')} />
      <AuthRoute exact path="/marketing/polls/new" component={withTitle(PollAdd, 'Poll')} privilege={admin('poll')} />
      <AuthRoute exact path="/marketing/reports" component={withTitle(FileReport, "Reports")} privilege='admin.report.center' />
      <AuthRoute exact path="/marketing/reports/file" component={withTitle(FileReport, "File Reports")} />
      <AuthRoute exact path="/advanced" component={withTitle(FormBuilder, "Settings")} />
      <AuthRoute exact path="/advanced/settings" component={withTitle(Settings, 'Settings')} privilege={sysadmin('globalvar')} />
      <AuthRoute exact path="/advanced/metadata" component={withTitle(Metadata, 'Metadata')} privilege={admin('meta')} />
      <AuthRoute exact path="/advanced/custom-fields" component={withTitle(FormBuilder, "Custom Fields")} privilege={customFieldsPrivileges} />
      <AuthRoute exact path="/revenue/products" component={withTitle(Products, 'Products')} privilege={admin('product')} />
      <AuthRoute exact path="/revenue/products/:oid" component={withTitle(ProductEdit, 'Product')} privilege={admin('product')} />
      <AuthRoute exact path="/revenue/products/new" component={withTitle(ProductAdd, 'Product')} privilege={admin('product')} />
      <AuthRoute exact path="/users" component={withTitle(Users, 'Users')} privilege={admin('user')} />
      <AuthRoute exact path="/users/:oid" component={withTitle(UserEdit, 'User')} privilege={admin('user')} />
      <AuthRoute exact path="/roles-privileges" component={withTitle(rolesPrivileges, 'Roles & Privileges')} privilege={sysadmin('privilege')} />
      <AuthRoute exact path="/search" component={withTitle(Search, "Search")} />
      <AuthRoute exact path="/workflow" component={withTitle(Workflow, "Workflow")} />
    </Switch>
  );
}

console.debug('Continuum Version 0.2.12');

export default App;


