import React, { useContext, useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Tooltip } from 'antd';
import { withRouter, useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { Context } from 'components/auth/context';
import { Context as ImpersonateContext } from 'components/impersonate/context';
import Notice from 'components/notice';
import { useAuthUser } from 'hooks/useAuthUser';
import OpenNotificationWithIcon from 'utils/notification';
import dateTimeSplit from "utils/dateTimeSplit";
import { clearLoginFromSiblingSite, environmentOptions, EnvironmentsType, isLoginFromSiblingSite, localStorageExists, stripEnvFromHost } from 'utils/helpers';
import * as css from './style';
import { FAL } from 'icons/fa';
import { LOCAL_STORAGE, ADMIN_CACHE } from 'constants/index';
import { sendMessage } from "registerServiceWorker";
import { Context as SettingsContext } from 'components/setting/context';
import Auth from 'auth';
import { useAuthPrivilege } from 'hooks/useAuthPrivilege';

const Search = withRouter((props) => {

  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');

  useDebounce(() => {
    if (!searchQuery) return;
    if (searchQuery === 'admin@epublishing.com' && location.pathname.includes('/users/')) {
      setSearchQuery('');
      return;
    }
    props.history.push({
      pathname: '/search',
      state: { searchQuery },
    });
  }, 300, [searchQuery]);

  return (
    <css.Search>
      <FAL icon="search" />
      <input placeholder="What are you looking for?" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} autoFocus={false} />
    </css.Search>
  );
});

// Verify if this browser supports local storage.
const hasLocalStorageSupport = localStorageExists();

/**
 * Allow the toggle between production and staging environments. Disable the staging option if local storage is not supported.
 * Since we use local storage to track each option.
 */
const Environment = () => {
  const ENV_OPTIONS = environmentOptions();
  const envRef = useRef(ENV_OPTIONS.X_ENV);
  const [env, setEnv] = useState<EnvironmentsType>(ENV_OPTIONS.X_ENV);

  // Set localStorage environment after first render.
  useEffect(() => {
    localStorage.setItem(`${LOCAL_STORAGE.X_ENV}:${ENV_OPTIONS.X_HOST_SHORTNAME}`, env);
  }, []);

  /**
   * Side-affects after changing the environment.
   * 1. Set new environment localstorage item
   * 2. Notify service-worker of auth and environment changes.
   * 3. Redirect to the next env URL
   */
  useEffect(() => {
    if (envRef.current === env && hasLocalStorageSupport) return;

    // Set new environment localstorage item
    envRef.current = env;
    localStorage.setItem(`${LOCAL_STORAGE.X_ENV}:${ENV_OPTIONS.X_HOST_SHORTNAME}`, env);

    // Notify service-worker of auth and environment changes.
    // FIXME: Might need to be removed. Commenting out to change URL instead of notifying service worker.
    const newData = { credentials: { environmentOptions: { ...ENV_OPTIONS }, isUserAuthenticated: Auth.isUserAuthenticated(), token: Auth.getToken() } };
    sendMessage(newData);

    // Redirect to the next env URL
    // const nextURL = env === 'stage' ? `https://${ENV_OPTIONS.HOST}-${env}.continuum.epublishing.com` : `https://${stripEnvFromHost(ENV_OPTIONS.HOST)}.continuum.epublishing.com`;
    // window.location = nextURL as unknown as Location;
    window.location.reload();
  }, [env]);

  const Staging = () => <css.EnvLink onClick={() => setEnv("stage")} active={env === "stage"}>Staging</css.EnvLink>;

  return (
    <css.Environment>
      <css.EnvLinks>
        {ENV_OPTIONS.X_HOST_SHORTNAME === "continuum-alpha" ? (
          <Tooltip title="No production environment exists"><css.EnvLink style={{ cursor: "not-allowed" }} active={env === "production"}>Production</css.EnvLink></Tooltip>
        ) : (
          <css.EnvLink onClick={() => setEnv("production")} active={env === "production"}>Production</css.EnvLink>
        )}
        {hasLocalStorageSupport ? (
          <Staging />
        ) : (
          <Tooltip title="This browser does not support local storage."><css.EnvLink style={{ cursor: "not-allowed" }}>Staging disabled</css.EnvLink></Tooltip>
        )}
      </css.EnvLinks>
    </css.Environment>
  );
};

const HelpLinks = (props) => {
  return <css.HelpLinks>
    <Tooltip title="Support">
      <css.HelpLink>
        <a href="http://support.epublishing.com" target="_blank" rel="noopener noreferrer" aria-label="Support Helpdesk"><FAL icon="life-ring" /></a>
      </css.HelpLink>
    </Tooltip>
    <Tooltip title="Documentation">
      <css.HelpLink>
        <a href="http://help.epublishing.com/m/92923" target="_blank" rel="noopener noreferrer" aria-label="Help / Documentation"><FAL icon="question-circle" /></a>
      </css.HelpLink>
    </Tooltip>
    <Tooltip title="Site">
      <css.HelpLink>
        <a href={`https://${props.siteUrlbase}`} target="_blank" rel="noopener noreferrer" aria-label="Live Site"><FAL icon="globe" /></a>
      </css.HelpLink>
    </Tooltip>
  </css.HelpLinks>
};

const User = withRouter((props) => {
  const { dispatch, state } = useContext(Context);
  const { firstName, lastName, error, loading, tokenExpiresAt } = useAuthUser();
  const [tokenAt, tokenAtFormatted] = tokenExpiresAt ? dateTimeSplit(tokenExpiresAt, 'MMM dd yyyy HH:mm a') : [null, null];
  // When token is used from cookie sent from sibling site
  // clear site login key from local storage
  useEffect(() => {
    if (loading || !isLoginFromSiblingSite()) return;

    clearLoginFromSiblingSite();
  }, [loading]);

  function logout() {
    dispatch({ type: "logout" });
    const newData = { credentials: {} };
    sendMessage(newData);
    props.history.push('/login');
  }

  if (error) return (<> `Error! ${error.message}`</>);
  return (
    <css.User>
      <css.HeaderLinks>
        <li>
          {(firstName || lastName) && <css.UserName>Welcome, {firstName} {lastName}</css.UserName>}
          <button className="signout-link" onClick={logout}>Sign Out</button>
        </li>
        <li>
          <span>Your session expires on {tokenAtFormatted}</span>
        </li>
      </css.HeaderLinks>
    </css.User>
  );
});

const ImpersonateMode = () => {
  const { state } = useContext(ImpersonateContext);
  return (
    (state.impersonateMode) ? <css.WarningText>
      <Notice type='caution' text={`Impersonate mode is on`} show={true} />
    </css.WarningText> : null
  )
}

const CLEAR_CACHE = gql`
  mutation CacheReset {
    cacheReset(input: { scope: null }) {
      success
      errors
    }
  }
`;

/**
 * Clear the Rails site cache.
 */
const ClearCacheButton = () => {
  const [clearChache] = useMutation(CLEAR_CACHE);
  const { hasPrivilege } = useAuthPrivilege({ privilege: ADMIN_CACHE });

  async function resetHandler() {
    const result = await clearChache();

    if (result.data.cacheReset.success) {
      OpenNotificationWithIcon({
        type: 'success',
        title: 'Site cache is now cleared.',
      }, () => { });
      return;
    }

    OpenNotificationWithIcon({
      type: 'error',
      title: 'There was an issue with clearing the site cache. Try again.',
    }, () => { });
  }

  return (
    <css.CacheButton>
      <Tooltip title={hasPrivilege ? "If you need a change reflected immediately on the site, clear the site cache." : ""}>
        <button onClick={resetHandler} disabled={!hasPrivilege}>Clear Site Cache</button>
      </Tooltip>
    </css.CacheButton>
  );
};

const Header = () => {
  const { settings } = useContext(SettingsContext);
  const siteUrlBase = settings['site.url.base']?.stringValue;
  const ENV_OPTIONS = environmentOptions();
  return (
    <>
      <css.Header env={ENV_OPTIONS.X_ENV}>
        <Search />
        <ClearCacheButton />
        <Environment />
        { siteUrlBase && <HelpLinks siteUrlbase={siteUrlBase}/> }
        <User />
      </css.Header>
      {/* <ImpersonateMode /> */}
    </>
  );
};

export default Header;
