import React from 'react';
import Helmet from 'react-helmet';
import { environmentOptions } from 'utils/helpers';

/**
 * Browser title component
 *
 * @param {string} title
 * @returns
 */
const TitleComponent = ({ title }) => {
  const defaultTitle = 'ePublishing CMS';
  const ENV_OPTIONS = environmentOptions();
  const env = ENV_OPTIONS.X_ENV;
  // Append "Stage" if env is "stage"
  const finalTitle = 
  title && title.toLowerCase() === 'login'
    ? title 
    : `${title ? title : defaultTitle}${env === 'stage' ? ' | Stage' : ''}`;

  return (
    <Helmet>
      <title>{finalTitle}</title>
    </Helmet>
  );
};

export { TitleComponent };